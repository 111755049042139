/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import 'modern-normalize/modern-normalize.css'
import '@fontsource/oxygen'
import '@fontsource/oxygen/300.css'
import '@fontsource/oxygen/700.css'
import '@fontsource/merriweather/300.css'
import './src/components/globalStyles.scss'
